import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vegan-shreds';
  menu = [
    {
      title: 'Articles',
      location: '/articles'
    },
    {
      title: 'About',
      location: '/about'
    },
    {
      title: 'Contact',
      location: '/contact'
    },
    {
      title: 'FAQ',
      location: '/faq'
    },
    {
      title: 'Shop',
      location: '/shop'
    }
  ];
}
