import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Social } from '../../../models/globals';

@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss']
})
export class InstagramFeedComponent implements OnInit {
  @Input() imageCount = 1;
  instaFeed = {};
  viewImage = false;
  currentImage: string;
  caption: string;
  postLink: string;

  constructor(private http: HttpClient, private social: Social) {}

  ngOnInit() {
    const feedUrl = `https://api.instagram.com/v1/users/2949021480/media/recent/?count=${
      this.imageCount
    }&access_token=2949021480.b340f7a.908ad13477a44a0c82d604bd31f07cec`;
    this.instaFeed = this.http.get(feedUrl).pipe(map(res => res));
  }

  view(item) {
    this.currentImage = item.images.standard_resolution.url;
    this.caption = item.caption.text;
    this.postLink = item.link;
    this.viewImage = true;
  }

  close() {
    this.viewImage = false;
  }
}
