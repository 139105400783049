import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  pageTitle = 'Frequently Asked<br />Questions';
  location: Location;
  scrolledTo = '0px';

  constructor() {}

  ngOnInit() {
    this.location = window.location;

    // window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy() {
    // window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (): void => {
    // console.log(window.scrollY);
    // this.scrolledTo = `${window.scrollY}px`;
  };
}
