import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';

type HeroType = 'image' | 'video';
type HeroSize = 'small' | 'large';
interface VideoFiles {
  mp4: string;
  ogv: string;
  webm: string;
}

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, AfterViewInit {
  @Input() bgImg;
  @Input() heroType: HeroType;
  @Input() heroSize: HeroSize;
  @Input() videoFiles: VideoFiles;
  @Input() minorText: string;

  @ViewChild('heroVideo', { read: ElementRef }) heroVideo: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.heroVideo) {
      const video: HTMLVideoElement = this.heroVideo.nativeElement;
      video.muted = true;
    }
  }
}
