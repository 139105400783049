import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() nav: any[];
  currentDate: Date = new Date();
  year = this.currentDate.getFullYear();

  constructor() {}

  ngOnInit() {}
}
