import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-video-cta',
  templateUrl: './video-cta.component.html',
  styleUrls: ['./video-cta.component.scss']
})
export class VideoCtaComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() text: string;
  @Input() video: string;

  viewVideo = false;

  constructor() {}

  ngOnInit() {}

  playVideo() {
    this.viewVideo = true;
  }

  close() {
    this.viewVideo = false;
  }
}
