import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  pageTitle = 'About Vegan Shreds';
  teamList = {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.teamList = this.http
      .get('assets/data/team-members.json')
      .pipe(map(res => res));
  }

  getDetails(member) {
    return [
      `Position: ${member.position}`,
      `Years Vegan: ${moment().diff(member.wentVegan, 'years')}`
    ];
  }
}
