import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-cta',
  templateUrl: './image-cta.component.html',
  styleUrls: ['./image-cta.component.scss']
})
export class ImageCtaComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() text: string;
  @Input() accent = false;
  @Input() accentColor = '#fd7000';
  @Input() sectionColor = '#0096b0';
  @Input() buttonText: string;

  @Output() buttonTrigger: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  triggered() {
    this.buttonTrigger.emit(this.buttonText);
  }
}
