import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-simple-details',
  templateUrl: './simple-details.component.html',
  styleUrls: ['./simple-details.component.scss']
})
export class SimpleDetailsComponent implements OnInit {
  @Input() img: string;
  @Input() title: string;
  @Input() text: string;
  @Input() subDetails = [];

  constructor() {}

  ngOnInit() {}
}
