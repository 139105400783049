import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  pageTitle = 'Shirts, Stickers,<br />&&nbsp;Downloads';
  productFeed = {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.productFeed = this.http
      .get('assets/data/product-list.json')
      .pipe(map(res => res));
  }

  getDetails(product) {
    return [`${product.price}`];
  }
}
