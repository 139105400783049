import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

type ColorTheme = 'primary' | 'secondary';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  productFeed = {};
  count = 3;
  @Input() accentColor: ColorTheme = 'secondary';

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.productFeed = this.http
      .get('assets/data/product-list.json')
      .pipe(map(res => res));
  }
}
