import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  pageTitle = 'Articles';
  articleList = {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.articleList = this.http
      .get('assets/data/article-list.json')
      .pipe(map(res => res));
  }

  getDetails(article) {
    return [`${article.posted}`, `Comments: ${article.comments}`];
  }
}
