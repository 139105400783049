import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-feed',
  templateUrl: './youtube-feed.component.html',
  styleUrls: ['./youtube-feed.component.scss']
})
export class YoutubeFeedComponent implements OnInit {
  @Input() videoCount = 1;
  ytFeed = {};
  ytVideo = null;
  viewVideo = false;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const feedUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&order=date&maxResults=${
      this.videoCount
    }&channelId=UCsnta4ak_Cvx_JIQDUXWU5w&key=AIzaSyAdxL3WKCpF3hKcso8CXk3ClzJLCrsCk-Q`;
    this.ytFeed = this.http.get(feedUrl).pipe(map(res => res));
  }

  view(item) {
    this.ytVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${item.id.videoId}`
    );
    this.viewVideo = true;
  }

  close() {
    this.viewVideo = false;
  }
}
