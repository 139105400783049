import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  videoFiles = {
    mp4: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
    ogv: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.ogv',
    webm: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.webm'
  };

  nutrients = [
    {
      img: 'assets/img/home/protiens.jpg',
      title: 'Complete Protiens',
      text:
        'Monotonectally deploy functionalized networks without focused "outside the box" thinking. Phosfluorescently restore one-to-one e-services before cross-platform initiatives. Holisticly procrastinate end-to-end vortals via principle-centered leadership.'
    },
    {
      img: 'assets/img/home/carbs.jpg',
      title: 'Simple Carbohydrates',
      text:
        'Objectively unleash timely functionalities without progressive total linkage. Assertively synergize premier niche markets without just in time infrastructures. Globally streamline multimedia based architectures vis-a-vis diverse content.'
    },
    {
      img: 'assets/img/home/fats.jpg',
      title: 'Healthy Fats',
      text:
        'Enthusiastically productize B2B e-markets whereas B2C processes. Competently conceptualize web-enabled web-readiness after next-generation communities. Completely empower customized meta-services rather than parallel relationships.'
    }
  ];

  ctaTitle = 'Vegan Macro Calculator';
  ctaText =
    'Uniquely exploit timely action items for focused benefits. Phosfluorescently generate accurate content without future-proof web-readiness. Synergistically monetize interoperable applications after process-centric mindshare. Globally embrace bleeding-edge data vis-a-vis top-line paradigms. Holisticly grow corporate web-readiness vis-a-vis unique deliverables.';

  videoctaTitle = 'Vegan Shreds 101';
  videoctaText =
    'Uniquely exploit timely action items for focused benefits. Phosfluorescently generate accurate content without future-proof web-readiness. Synergistically monetize interoperable applications after process-centric mindshare. Globally embrace bleeding-edge data vis-a-vis top-line paradigms. Holisticly grow corporate web-readiness vis-a-vis unique deliverables.';

  constructor() {}

  ngOnInit() {}

  getStarted(event) {
    console.log(event);
  }

  playVideo(event) {
    console.log(event);
  }
}
